<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Nuevo anuncio</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <new-ad-skeleton v-if="loading"></new-ad-skeleton>
        <v-container v-if="!loading" fluid>
          <v-row>
            <v-col
              style="
                text-align: center;
                display: flex;
                justify-content: center;
                align-item: center;
              "
              cols="12"
              sm="4"
              md="4"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <img
                    v-on="on"
                    @click.prevent="showFileChooser"
                    class="ad-img mt-5"
                    :src="imgPreview"
                  />
                  <div
                    v-on="on"
                    v-if="!imgPreview"
                    @click.prevent="showFileChooser"
                    class="img-container mt-5"
                  >
                    <i style="font-size: 30px" class="fas fa-image"></i>
                    <span class="ml-2">Seleccionar imágen</span>
                  </div>
                  ç
                </template>
                <span>Clic para cambiar la imágen</span>
              </v-tooltip>

              <input
                class="upload"
                ref="input"
                type="file"
                name="image"
                accept="image/*"
                @change="imageChanged"
              />
            </v-col>

            <v-col cols="12" sm="8" md="8">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span>General</span>
                  <v-divider class="mb-5"></v-divider>

                  <p>Nombre del anuncio:</p>
                  <input
                    class="control-input"
                    type="text"
                    placeholder="Ingrese el nombre"
                    v-model="ad.name"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <p>Al dar clic:</p>
                  <v-select
                    :items="actions"
                    v-model="ad.command"
                    placeholder="Elija un comando"
                    item-text="text"
                    item-value="value"
                    class="select-city mt-6"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <p>URL:</p>
                  <input
                    :disabled="ad.command != 'action'"
                    class="control-input"
                    type="text"
                    placeholder="Ingrese la URL a redireccionar"
                    v-model="ad.url"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span>Fechas de visualización</span>
              <v-divider class="mb-5"></v-divider>

              <v-row>
                <v-col sm="12" cols="5" md="5">
                  <v-date-picker
                    no-title
                    scrollable
                    v-model="dates"
                    range
                  ></v-date-picker>
                </v-col>

                <v-col sm="12" cols="7" md="7">
                  <v-row>
                    <v-col cols="12" sm="7" md="7">
                      <p>Fecha de inicio:</p>
                      <input
                        class="control-input"
                        type="text"
                        placeholder="Fecha de inicio"
                        v-model="dates[0]"
                      />
                    </v-col>

                    <v-col cols="12" sm="5" md="5">
                      <p>Hora de inicio:</p>
                      <a-time-picker
                        :open.sync="time.startsActive"
                        class="time-picker"
                        @change="changeTime($event, 1)"
                        format="HH:mm"
                        :default-value="moment('08:00', 'HH:mm')"
                      >
                        <a-button
                          @click="handleClose()"
                          style="border-radius: 50px"
                          slot="addon"
                          block
                          size="small"
                          type="primary"
                        >
                          Aceptar
                        </a-button>
                      </a-time-picker>
                    </v-col>
                    <v-col cols="12" sm="7" md="7">
                      <p>Fecha final:</p>
                      <input
                        class="control-input"
                        type="text"
                        placeholder="Fecha final"
                        v-model="dates[1]"
                      />
                    </v-col>

                    <v-col cols="12" sm="5" md="5">
                      <p>Hora final:</p>
                      <a-time-picker
                        :open.sync="time.endsActive"
                        class="time-picker"
                        @change="changeTime($event, 2)"
                        format="HH:mm"
                        :default-value="moment('19:00', 'HH:mm')"
                      >
                        <a-button
                          @click="handleClose()"
                          style="border-radius: 50px"
                          slot="addon"
                          block
                          size="small"
                          type="primary"
                        >
                          Aceptar
                        </a-button>
                      </a-time-picker>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <span>Opciones de visualización</span>
              <v-divider class="mb-5"></v-divider>

              <p>Ciudades:</p>
              <v-autocomplete
                :items="cities"
                v-model="ad.cities"
                placeholder="Elija las ciudades en las que se mostrará el anuncio"
                class="select-city mt-6"
                outlined
                chips
                multiple
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Mostrar en las siguientes categorias:</p>
              <v-autocomplete
                :items="getCategories"
                v-model="selectedCategories"
                placeholder="Elija las categorías en las que se mostrará el anuncio"
                class="select-city mt-6"
                outlined
                chips
                multiple
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Mostrar en los siguientes comercios:</p>
              <v-autocomplete
                :items="getBusinesses"
                v-model="selectedBusinesses"
                placeholder="Elija los comercios en los que se mostrará el anuncio"
                class="select-city mt-6"
                outlined
                chips
                multiple
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions v-if="!loading">
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch v-model="ad.active" label="Anuncio activo"></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn @click="saveAd()" class="save-btn mt-4" color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import newAdSkeleton from "../skeleton/new-ad-skeleton";
import { Button } from "ant-design-vue";
import { TimePicker } from "ant-design-vue";

export default {
  name: "new-ad",
  components: {
    lottie: Lottie,
    newAdSkeleton,
    aTimePicker: TimePicker,
    aButton: Button,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      ad: {
        name: "",
        createdAt: "",
        createdBy: this.$store.state.user[".key"],
        deleted: false,
        command: "",
        url: "",
        active: true,
        cities: [],
        displayIn: [],
      },
      cities: [],
      citiesName: [],
      actions: [
        { text: "Ejecutar una acción", value: "action" },
        { text: "Descartar anuncio", value: "dismiss" },
      ],
      categories: [],
      selectedCategories: [],
      businesses: [],
      selectedBusinesses: [],
      startsAt: "", //date
      endsAt: "", //date
      time: {
        startsAt: "08:00:00",
        endsAt: "19:00:00",
        startsActive: false,
        endsActive: false,
      },
      menuStartsAt: false,
      menuEndsAt: false,
      dates: ["", ""],
      imageFile: "",
      imgPreview: "",
      newTag: "",
      types: [
        {
          value: "food",
          text: "Comida",
        },
        {
          value: "drinks",
          text: "Bebida",
        },
        {
          value: "convenience",
          text: "Conveniencia",
        },
        {
          value: "courier",
          text: "Mensajeria",
        },
        {
          value: "retail",
          text: "Tiendas",
        },
        {
          value: "events",
          text: "Eventos",
        },
        {
          value: "amenities",
          text: "Amenidades",
        },
        {
          value: "services",
          text: "Servicios",
        },
      ],
    };
  },
  async mounted() {
    let cities = this.$binding(
      "cities",
      db.collection("cities").where("deleted", "==", false)
    ).then((cities) => {
      this.cities = this.cities.map((item) => {
        return {
          text: item.name,
          value: item[".key"],
        };
      });
    });

    let businesses = this.$binding(
      "businesses",
      db.collection("businesses").where("deleted", "==", false)
    ).then((businesses) => {
      this.loading = false;

      this.businesses = this.businesses.map((item) => {
        return {
          text: item.shortName,
          value: item[".key"],
          ochoCategories: item.ochoCategories,
        };
      });
    });

    let categories = this.$binding(
      "categories",
      db.collection("categories").where("deleted", "==", false)
    ).then((categories) => {
      this.loading = false;

      this.categories = this.categories.map((item) => {
        return {
          text: item.name,
          value: item[".key"],
          city: item.city,
        };
      });
    });

    await Promise.all([cities, businesses, categories]);
    this.loading = false;
  },
  watch: {
    "ad.cities"(data) {
      let cities = this.cities.filter((item) =>
        this.ad.cities.includes(item.value)
      );
      this.citiesName = cities.map((item) => {
        return item.text;
      });
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    validate() {
      if (
        !this.ad.name ||
        this.ad.cities.length == 0 ||
        (this.selectedCategories.length == 0 &&
          this.selectedBusinesses.length == 0) ||
        !this.ad.command ||
        (this.ad.command == "action") & !this.ad.url
      ) {
        this.snackbarText = "Debe llenar todos los campos.";
        this.snackbar = true;
        return false;
      } else if (
        !this.dates[0] ||
        !this.dates[1] ||
        !this.time.startsAt ||
        !this.time.endsAt
      ) {
        this.snackbarText =
          "Debe llenar las fechas de visualización del anuncio";
        this.snackbar = true;
        return false;
      } else if (!this.imageFile) {
        this.snackbarText = "Debe elegir una imágen.";
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },

    generateTimestamp() {
      return new Promise((resolve, reject) => {
        let date = this.dates[0].split("-");
        let time = this.time.startsAt.split(":");

        this.ad.startsAt = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt(time[0]),
            parseInt(time[1]),
            parseInt(time[2])
          )
        );

        date = this.dates[1].split("-");
        time = this.time.endsAt.split(":");
        this.ad.endsAt = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt(time[0]),
            parseInt(time[1]),
            parseInt(time[2])
          )
        );
        resolve(true);
      });
    },

    getCategories() {
      return this.categories.filter((cat) => this.ad.cities.includes(cat.city));
    },

    getBusinesses() {
      return this.businesses.filter((item) =>
        item.ochoCategories.some((cat) => this.selectedCategories.includes(cat))
      );
    },
  },
  methods: {
    moment,
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    async saveAd() {
      if (this.validate) {
        await this.generateTimestamp;
        this.ad.displayIn = this.selectedBusinesses.concat(
          this.selectedCategories
        );
        this.ad.createdAt = new Date();
        this.saving = true;
        db.collection("ads")
          .add(this.ad)
          .then((ref) => {
            if (this.imageFile) {
              fb.app()
                .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-ads`)
                .ref()
                .child(`${ref.id}/${this.imageFile.name}`)
                .put(this.imageFile)
                .then((res) => {
                  this.saving = false;
                  this.$emit("success");
                })
                .catch((err) => {
                  this.snackbarText =
                    "Ocurrió un error al subir la imágen, actualice desde la página de anuncios";
                  this.snackbar = true;
                  this.saving = false;
                });
            } else {
              this.saving = false;
              this.$emit("success");
            }
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
    handleClose() {
      this.time.startsActive = false;
      this.time.endsActive = false;
    },

    changeTime(time, index) {
      if (index == 1) {
        this.time.startsAt = moment(time).format("HH:mm:ss");
      } else {
        this.time.endsAt = moment(time).format("HH:mm:ss");
      }
    },

    showFileChooser() {
      this.$refs.input.click();
    },

    imageChanged(e) {
      if (!e.target.files[0]) return;

      let ref = this;
      this.imageFile = e.target.files[0];

      if (this.imageFile && this.imageFile.type.indexOf("image/") === -1) {
        console.log("tipo de archivo no permitido");
        this.imageFile = null;
      }

      var reader = new FileReader();
      reader.onload = function (e) {
        ref.imgPreview = e.target.result;
      };

      if (this.imageFile) reader.readAsDataURL(this.imageFile);
      else this.imgPreview = null;
    },
  },
};
</script>

<style>
.category-color {
  -webkit-appearance: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  height: 45px !important;
  cursor: pointer;
}

.category-color::-webkit-color-swatch {
  border-radius: 50px;
  padding: 0;
}
.category-color::-webkit-color-swatch-wrapper {
  border-radius: 50px;
  padding: 0;
}

.select-city {
  border-radius: 50px;
  padding: 0;
}

.control-input {
  border-color: rgba(117, 117, 117, 0.582);
}

.ad-img {
  max-width: 100%;
  max-height: 400px;
  cursor: pointer;
}

.img-container {
  max-width: 100%;
  width: 100%;
  max-height: 400px;
  min-height: 400px;
  cursor: pointer;
  background-color: #92929242;
  border: 1px solid #808080b3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-picker .ant-time-picker-input {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  color: black;
  height: 44px;
}

.upload {
  display: none;
}
</style>