<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Anuncios</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            @click="
              modalNewAd = true;
              randomKey = Math.random();
            "
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nuevo anuncio
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="ads"
          :items-per-page="15"
          :loading="loading"
          :search="$store.state.search"
          :sort-desc="true"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>

          <template v-slot:[`item.startsAt`]="{ item }">
            {{ item.startsAt | filterDate }}
          </template>

          <template v-slot:[`item.endsAt`]="{ item }">
            {{ item.endsAt | filterDate }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="viewAd(item)" small color="primary">Editar</v-btn>
            <v-btn
              @click="deleteCity(item)"
              small
              class="ml-3 white--text"
              color="red darken-4"
              >Eliminar</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="adSelected" v-model="modalEditAd" max-width="800px">
      <edit-ad
        @cancel="modalEditAd = false"
        @success="handleUpdate"
        :key="adSelected['.key']"
        :ad="adSelected"
      ></edit-ad>
    </v-dialog>

    <v-dialog persistent v-model="modalNewAd" max-width="800px">
      <new-ad
        @cancel="modalNewAd = false"
        @success="handleCreate"
        :key="randomKey"
      ></new-ad>
    </v-dialog>

    <v-dialog max-width="400" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ itemToDelete.name }}</span
          >?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import newAd from "./new-ad";
import editAd from "./edit-ad";

export default {
  name: "ads",
  components: {
    lottie: Lottie,
    newAd,
    editAd,
  },
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      modalEditBusinesses: false,
      snackbarText: "",
      itemToEdit: {},
      itemToDelete: null,
      modalDelete: false,
      citySelected: null,
      adSelected: null,
      modalEditAd: false,
      ads: [],
      randomKey: 0,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      modalNewAd: false,
      anim: null,
      animationSpeed: 1.1,
      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Acción",
          value: "command",
        },
        {
          text: "Activo",
          value: "active",
        },

        {
          text: "Creada",
          value: "createdAt",
        },
        {
          text: "Fecha inicio",
          value: "startsAt",
        },
        {
          text: "Fecha final",
          value: "endsAt",
        },

        {
          value: "options",
          align: "end",
          width: "200px",
        },
      ],
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },

  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    deleteCity(item) {
      this.itemToDelete = item;
      this.modalDelete = true;
    },

    handleCreate() {
      this.modalNewAd = false;
      this.snackbarText = "Anuncio creado exitosamente.";
      this.snackbar = true;
    },

    viewAd (item) {
      this.adSelected = item;
      this.modalEditAd = true;
    },

    handleUpdate() {
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
      this.modalEditAd = false;
    },

    switchControlChanged(data, adID, ad) {
      if (data && adID) {
        this.saving = true;

        db.collection("ads")
          .doc(adID)
          .update({
            active: data.active,
          })
          .then((ref) => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            ad.active = !ad.active;
          });
      }
    },

    confirmDelete() {
      if (this.itemToDelete) {
        db.collection("ads")
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Anuncio eliminado";
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
  },

  mounted() {
    this.$store.commit("setSearchTerm", "anuncio");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    this.$binding(
      "ads",
      db.collection("ads").where("deleted", "==", false)
    ).then((ads) => {
      this.loading = false;
    });
  },
};
</script>